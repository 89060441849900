<template>
<CSidebar position="fixed" :unfoldable="sidebarUnfoldable" :visible="sidebarVisible"
	@visible-change="(event)=>$store.commit({'type': 'updateSidebarVisible', 'value': event})"
	:class="{'hide': sidebar_hide}"
>
	<CSidebarBrand>
		<div>{{ user_id }}</div>
		<CButton size="sm" color="dark" @click="popup_view=true">변경</CButton>
	</CSidebarBrand>
	<AppSidebarNav />
	<!-- <CSidebarToggler
		class="d-none d-lg-flex"
		@click="$store.commit('toggleUnfoldable')"
	/> -->
</CSidebar>

<div data-password_change_popup>
	<CModal alignment="center" :visible="popup_view" @close="()=>{popup_view=false}">
		<CForm @submit.prevent="setPasswordChange">
			<CModalHeader><CModalTitle>비밀번호 변경</CModalTitle></CModalHeader>
			<CModalBody>
				<CListGroup size="sm">
					<CListGroupItem>
						<CInputGroup size="sm">
							<CInputGroupText>기존 비밀번호</CInputGroupText>
							<CFormInput name="pwd" type="password" placeholder="비밀번호를 입력해 주세요." required />
						</CInputGroup>
					</CListGroupItem>
					<CListGroupItem>
						<CInputGroup size="sm">
							<CInputGroupText>비밀번호</CInputGroupText>
							<CFormInput name="userPwd" type="password" placeholder="비밀번호를 입력해 주세요." required />
						</CInputGroup>
					</CListGroupItem>
					<CListGroupItem>
						<CInputGroup size="sm">
							<CInputGroupText>비밀번호 확인</CInputGroupText>
							<CFormInput name="userPwdChk" type="password" placeholder="비밀번호를 입력해 주세요." required
								@input="(event)=>event.target.setCustomValidity('')" />
						</CInputGroup>
					</CListGroupItem>
				</CListGroup>
			</CModalBody>
			<CModalFooter>
				<CButton type="submit" size="sm" color="success" class="text-white">변경</CButton>
			</CModalFooter>
		</CForm>
	</CModal>
</div>
</template>
<script>
import {ref, computed} from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
import api_admin_account from "@/api/admin/adminAccount";
import{ useRoute }from"vue-router";
export default {
	name: 'AppSidebar',
	components: {
		AppSidebarNav,
	},
	created(){
		// console.log( this.$store.state )
	},
	setup() {
		const store = useStore()
		const user_id = localStorage.getItem("user_id");
		const popup_view = ref( false );
		const route = useRoute();
		const sidebar_hide = route.path.indexOf("/chatting-room/") > -1;
		const setPasswordChange = ( event )=>{const target=event.target;
			if(target["userPwd"].value !== target["userPwdChk"].value){
				target["userPwdChk"].setCustomValidity("비밀번호가 일치하지 않습니다.");
				target["userPwdChk"].reportValidity();
				return;
			}
			api_admin_account.setPasswordChange(target["pwd"].value, target["userPwd"].value).then(( response )=>{
				const data=response.data;
				if(data.code !== 0){ alert( data.message ); return;}
				popup_view.value = false;
				alert("비밀번호를 변경했습니다.");
			});
		}
		return{
			user_id,
			popup_view,
			logoNegative,
			sygnet,
			sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
			sidebarVisible: computed(() => store.state.sidebarVisible),
			setPasswordChange,
			sidebar_hide
		}
	},
}
</script>
<style scoped lang="sass">
.sidebar-brand
	display: flex
	gap: 10px
[data-password_change_popup]
	::v-deep( .modal-body )
		.input-group-text
			justify-content: flex-end
			width: 100px
	::v-deep( .modal-footer )
		justify-content: center
		button
			width: 100px
</style>