<template>
<CHeader position="sticky" class="mb-4" v-if="header_show">
	<CContainer fluid>
		<CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
			<CIcon icon="cil-menu" size="lg" />
		</CHeaderToggler>
		<AppBreadcrumb />
		<CButton
			color="danger"
			size="xl"
			@click="
				user.logout()
			"
		>
		로그아웃</CButton>
	</CContainer>
</CHeader>
</template>
<script setup>
import { logo } from "@/assets/brand/logo";
import AppBreadcrumb from "./AppBreadcrumb";
import AppHeaderDropdownAccnt from "./AppHeaderDropdownAccnt";
import user from '@/api/user/user'
import{ useRoute }from"vue-router";
const route = useRoute();
const header_show = route.path.indexOf("/chatting-room/") === -1;
</script>
